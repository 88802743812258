import React, { useContext } from 'react'
import { graphql } from 'gatsby'
import PropTypes from 'prop-types'
import SEO from 'components/shared/SEO'
import Slider from 'components/Slider/Slider'
import Heading from 'components/shared/Heading'
import Section from 'components/shared/Section'
import Content from 'components/shared/Content'
import Investments from 'components/Investments/Investments'
import Experience from 'components/Experience/Experience'
import Map from 'components/Map/Map'
import About from 'components/About/About'
import Promo from 'components/Promo/Promo'
import Contact from 'components/Contact/Contact'
import { LangContext } from 'contexts/LangContext'
import slugify from 'slugify'

const IndexPage = ({ data }) => {
  const homePage = data.wpgraphql.page.PageHome

  const offers = data.wpgraphql.offers.nodes
  const { lang } = useContext(LangContext)

  const slides = homePage.homeBannerSlider.map(slide => ({
    src: slide.homeBannerSliderImg.imageFile.childImageSharp.fluid,
    alt: slide.homeBannerSliderImg.altText || 'slide image',
    highlightedText:
      slide.homeBannerSliderTitle &&
      slide.homeBannerSliderTitle.replace(/\{(.*?)\}/g, '').trim(),
    text:
      slide.homeBannerSliderTitle &&
      slide.homeBannerSliderTitle.match(/\{(.*?)\}/g)[0].replace(/[{()}]/g, ''),
  }))

  const currentLangOffers = offers.filter(({ slug }) =>
    slug.match(`${lang.toLowerCase()}_(.*?)`)
  )

  const investments = currentLangOffers.map(
    ({ PageOfferSingle, slug, title }) => ({
      id: slug,
      image:
        PageOfferSingle.invCardThumb &&
        PageOfferSingle.invCardThumb.imageFile.childImageSharp.fluid,
      imageAlt:
        (PageOfferSingle.invCardThumb &&
          PageOfferSingle.invCardThumb.altText) ||
        PageOfferSingle.invCardCity,
      city: PageOfferSingle.invCardCity,
      district: PageOfferSingle.invCardDistrict,
      status: PageOfferSingle.invCardStatus,
      name: title,
      description:
        PageOfferSingle.invCardDesc &&
        PageOfferSingle.invCardDesc.replace(/(<|&lt;)br\s*\/*(>|&gt;)/g, ' '),
      link: `/${slugify(title, { lower: true })}`,
      etiquete: PageOfferSingle.invCardLabelText,
    })
  )

  const projects = homePage.homePortfolioList.map(project => ({
    id: project.homePortfolioListName,
    image:
      project.homePortfolioListImg &&
      project.homePortfolioListImg.imageFile.childImageSharp.fluid,
    imageAlt:
      (project.homePortfolioListImg && project.homePortfolioListImg.altText) ||
      `${project.homePortfolioListLoc} image`,
    link: project.homePortfolioListBtnUrl,
    city: project.homePortfolioListLoc,
    name: project.homePortfolioListName,
    description: project.homePortfolioListText.replace(
      /(<|&lt;)br\s*\/*(>|&gt;)/g,
      ' '
    ),
    status: project.homePortfolioListStatus,
    etiquete: project.homePortfolioListLabelText,
  }))

  const estates = ['Berlin', 'Leipzig']

  const aboutCards = homePage.homeBoxes.map(card => ({
    id: card.homeBoxesTitle,
    image: card.homeBoxesImg.imageFile.childImageSharp.fluid,
    imageAlt: card.homeBoxesImg.altText || 'card image',
    teaserNum: card.homeBoxesNum,
    header: {
      title:
        card.homeBoxesTitle &&
        card.homeBoxesTitle.replace(/\{(.*?)\}/g, '').trim(),
      highlight:
        card.homeBoxesTitle &&
        card.homeBoxesTitle.match(/\{(.*?)\}/g)[0].replace(/[{()}]/g, ''),
    },
    teaser: {
      number: card.teaserNum,
      text: card.homeBoxesNumText,
    },
    description:
      card.homeBoxesText &&
      card.homeBoxesText.replace(/(<|&lt;)br\s*\/*(>|&gt;)/g, ' '),
  }))

  const mainLocalization = {
    id: `${lang}_${homePage.homeFormTitle}`,
    name: homePage.homeFormTitle,
    image: homePage.homeFormImg.imageFile.childImageSharp.fluid,
    imageAlt: homePage.homeFormImg.altText || `${homePage.homeFormTitle} image`,
    localization: [homePage.homeFormAddress],
    phone: homePage.homeFormPhone,
    email: homePage.homeFormEmail,
  }

  const subLocalizations = homePage.homeOffices.map(office => ({
    id: office.homeOfficesCity,
    city: office.homeOfficesCity,
    name: office.homeOfficesName,
    image: office.homeOfficesImg.imageFile.childImageSharp.fluid,
    imageAlt:
      office.homeOfficesCity.altText ||
      `${office.homeOfficesCity} office image`,
    localization: [office.homeOfficesAddress],
    phone: office.homeOfficesPhone,
    email: office.homeOfficesEmail,
  }))

  return (
    <>
      <SEO title="Homepage" lang="en" />
      <div id="home" />
      <Slider
        items={slides}
        info={homePage.homeBannerFooter}
        scrollTo="#offer"
      />
      <main>
        <Content>
          <Section id="offer">
            <Heading
              big
              title={
                homePage.homeOfferHeading &&
                homePage.homeOfferHeading.replace(/\{(.*?)\}/g, '').trim()
              }
              highlight={
                homePage.homeOfferHeading &&
                homePage.homeOfferHeading
                  .match(/\{(.*?)\}/g)[0]
                  .replace(/[{()}]/g, '')
              }
            />
            <Investments investments={investments} />
          </Section>
        </Content>
        <Map
          title={
            homePage.homeMapHeading &&
            homePage.homeMapHeading.replace(/\{(.*?)\}/g, '').trim()
          }
          highlight={
            homePage.homeMapHeading &&
            homePage.homeMapHeading
              .match(/\{(.*?)\}/g)[0]
              .replace(/[{()}]/g, '')
          }
          estates={estates}
        />
        <About background={homePage.homeBoxesBg.sourceUrl} cards={aboutCards} />
        <Promo />
        <Section id="experience">
          <Content>
            <Heading
              bigFrom="s"
              title={homePage.homePortfolioHeading
                .replace(/\{(.*?)\}/g, '')
                .trim()}
              highlight={homePage.homePortfolioHeading
                .match(/\{(.*?)\}/g)[0]
                .replace(/[{()}]/g, '')}
            />
          </Content>
          <Experience projects={projects} />
        </Section>
        <Contact
          title={homePage.homeFormHeading}
          subLocalizations={subLocalizations}
          mainLocalization={mainLocalization}
        />
      </main>
    </>
  )
}

export const query = graphql`
  query HomePageEN {
    wpgraphql {
      page(id: "cG9zdDoxNDc=") {
        PageHome {
          homeBannerFooter
          homeBannerSlider {
            homeBannerSliderImg {
              sourceUrl
              altText
              imageFile {
                childImageSharp {
                  fluid(maxHeight: 1080) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
            homeBannerSliderTitle
          }
          homeOfferHeading
          homeBoxesBg {
            sourceUrl
          }
          homeMapHeading
          homePortfolioHeading
          homePortfolioList {
            homePortfolioListImg {
              sourceUrl
              altText
              imageFile {
                childImageSharp {
                  fluid(maxHeight: 600) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
            homePortfolioListBtnUrl
            homePortfolioListName
            homePortfolioListStatus
            homePortfolioListText
            homePortfolioListLoc
          }
          homeBoxes {
            homeBoxesTitle
            homeBoxesText
            homeBoxesNum
            homeBoxesNumText
            homeBoxesImg {
              sourceUrl
              altText
              imageFile {
                childImageSharp {
                  fluid(maxHeight: 600) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
          homeFormSitz
          homeFormPhone
          homeFormHeading
          homeFormSteuernummer
          homeFormEmail
          homeFormAddress
          homeFormTitle
          homeFormImg {
            sourceUrl
            altText
            imageFile {
              childImageSharp {
                fluid(maxHeight: 600) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          homeOffices {
            homeOfficesEmail
            homeOfficesCity
            homeOfficesAddress
            homeOfficesName
            homeOfficesPhone
            homeOfficesImg {
              sourceUrl
              altText
              imageFile {
                childImageSharp {
                  fluid(maxHeight: 600) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
        }
      }
      offers: offerPlural(first: 100) {
        nodes {
          slug
          title
          PageOfferSingle {
            invCardCity
            invCardDesc
            invCardDistrict
            invCardStatus
            invCardLabelText
            invCardThumb {
              sourceUrl
              imageFile {
                childImageSharp {
                  fluid(maxHeight: 300) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

IndexPage.propTypes = {
  data: PropTypes.objectOf(PropTypes.object).isRequired,
}

export default IndexPage
